<template>
  <div class="page home">
    <div class="pageHeader">
      <h1 class="pageTitle">LeePlus</h1>
      <p class="pageDesc">LeePlus 是一套移动端样式的Vue3组件。</p>
    </div>
    <div class="pageBody">
      <div class="panel">
        <div class="category">基础组件</div>
        <div class="categoryInner">
          <router-link to="Button" class="nav clickable">
            Button 按钮
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="Cell" class="nav clickable">
            Cell 单元格
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="Icon" class="nav clickable">
            Icon 图标
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="Popup" class="nav clickable">
            Popup 弹出层
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="Toast" class="nav clickable">
            Toast 轻提示
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
        </div>
      </div>
      <div class="panel">
        <div class="category">表单组件</div>
        <div class="categoryInner">
          <router-link to="/Calendar" class="nav clickable ok">
            Calendar 日历
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="/DatePicker" class="nav clickable ok">
            DatePicker 日期选择
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="/Picker" class="nav clickable ok">
            Picker 选择器
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="/Switch" class="nav clickable">
            Switch 开关
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
        </div>
      </div>
      <div class="panel">
        <div class="category">反馈组件</div>
        <div class="categoryInner">
          <router-link to="/ActionSheet" class="nav clickable">
            ActionSheet 动作面板
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="/Dialog" class="nav clickable">
            Dialog 弹出框
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="/DropdownMenu" class="nav clickable">
            DropdownMenu 下拉菜单
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="/Loading" class="nav clickable">
            Loading 加载
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="/PullRefresh" class="nav clickable">
            PullRefresh 下拉刷新
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
        </div>
      </div>
      <div class="panel">
        <div class="category">展示组件</div>
        <div class="categoryInner">
          <router-link to="/Badge" class="nav clickable">
            Badge 徽标
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="/Collapse" class="nav clickable">
            Collapse 折叠面板
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
          <router-link to="/Tag" class="nav clickable">
            Tag 标签
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
        </div>
      </div>
      <div class="panel">
        <div class="category">导航组件</div>
        <div class="categoryInner">
          <router-link to="/IndexBar" class="nav clickable">
            IndexBar 索引栏
            <i class="lee-icon lee-icon-arrow-right"></i>
          </router-link>
        </div>
      </div>
      <div class="panel">
        <div class="category">业务组件</div>
        <div class="categoryInner">

        </div>
      </div>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
